import React from 'react';
import '../css/landing.css';

const Landing = () => {
    return (
        <div id="landingmain">
            <div className="rpititle">
                <div className="primarytitle">Rowley</div>      
                <div className="secondarytitle">
                    <span className='plumbinginc'>Plumbing Inc.</span>
                    <span className='established'>Established 1973</span>
                </div>
            </div> 
        </div>
    );
};

export default Landing;