import React, { Component } from 'react';
import '../css/navbar.css';
import Homelogo from '../Images/Final Logo.png';

export default class Navbar extends Component {

    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({
            hiddenmenu: false,
        })
    }

    render() {
        return (
            <div onClick={
                () => this.scrollToTop()
            } className={
                "navbarmainsh"
                 }>
                <div className={
                    'logosh'    
                }><img className={
                    'rowleylogosmall'
                } src={Homelogo} alt="home-logo"/></div>
            </div>
        );
    }
}
